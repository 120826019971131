const THEME_COLORS = {
    light: {
        primary: {
            main: '#288FD4',
            alt: '#16568a',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ABCC61',
            alt: '#c5db95',

            contrastText: '#ffffff'
        },
        text: {
            paragraph: '#828588',
            titles: '#232F3E',
            dark: '#232F3E',

        },
        bg: {
            main: '#ffffff',
            alt: '#f2f2f2',
            darken: '#d9d9d6',
            white: '#ffffff',
        },
        line: {
            main: '#f2f2f2',
            darken: '#d9d9d6'
        },
        logo: {
            main: '#166099',
            contrastText: '#ffffff'
        }
    },

    dark: {
        primary: {
            main: '#0090da',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#a4ce4e',
            contrastText: '#ffffff'

        },
        text: {
            paragraph: '#FCF8FF',
            titles: '#FCF8FF',
        },
        bg: {
            alt: '#131A22',
            main: '#232F3E',
            light: '#f0f2f5'
        },
        line: '#1c1c1e'
    },




    alerts: {
        danger: {
            main: '#FA3E3E',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#F0AD4E',
            contrastText: '#ffffff'
        },
        success: {
            main: '#5cb85c',
            contrastText: '#ffffff'
        },
        info: {
            main: '#006c70',
            contrastText: '#ffffff'
        },
    }




}

const CSS_HELPERS_REACT = {
    CENTER: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    CENTER_VERTICAL: {
        display: 'flex',
        alignItems: 'center',
    },

    DISTRIBUTE_COLUM: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1em'
    },
    TRUNCATE: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    BOX_SHADOW: {
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 4px "
    },
    BOX_SHADOW_BOTTOM: {
        boxShadow: "0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.09)",
        transitionDuration: '172ms',
        willChange: 'transform, box-shadow'
    },
    BOX_SHADOW_TOP: {
        boxShadow: '0 -3px 5px 0 rgba(0,0,0,0.05)',
        transitionDuration: '172ms',
        willChange: 'transform, box-shadow'
    },

    RADIUS: {
    },
    BORDER_BOTTOM: {
        borderBottom: "1px solid #d1d7e0",
        padding: '.9em 0',
    },

    Y_SCROLL: {
        // height:"100%",
        // overflowY:'scroll'
    },

    Y_SCROLL_CONTAINER: {
        // height:'100%', overflow:'hidden'
    }

};

const CSS_HELPERS = {

    CENTER: `
        display:flex;
        align-items: center;
        flex-direction:row;
    `,

    CENTER_HORIZONTAL: `
        display:flex;
        justify-content: center;
    `,
    BOX_SHADOW: `
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;    
    `,

    BOTTOM: ``,

    TSCALE: `
        transition: all .2s ease-in;
        cursor:pointer;
        :hover {
            transform: scale(1.05);        
        }  
    `,

    T_ELEVATE: `
        transition: all .2s ease-in;
        cursor:pointer;
        :hover {
            transform: translateY(-1pt) translate(-1pt);        
            box-shadow: rgba(149, 157, 165, 0.2) 3pt 3pt 0;    
        }
    `,

    BOX_SHADOW_TOP: `
        box-shadow: 0 -3px 5px 0 rgba(0,0,0,0.05);
        transition-duration: 172ms;
        will-change: transform, box-shadow;  
    `,
    BOX_SHADOW_BOTTOM: `
        box-shadow: 0 1px 3px rgba(0,0,0,0.06);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);   
    `,

    BOX_TAILWIND: `box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;`,

    TSCALE_DIASPEAR: `
    transition: all .6s ease-in;
    cursor:pointer;
    :hover {
        transform: scale(2);   
        opacity:0;   
        box-shadow: 0 1px 3px rgba(0,0,0,0.06);
    }
    
    
    `,


    TRUNCATE: `
        padding:0;
        margin:0;
        // width: 100%;
        overflow: hidden;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
  `,

    BORDER_LINE_TOP: `
        border-top: .6em solid ${THEME_COLORS.light.secondary.main};
    `,

    BORDER_LINE_BOTTOM: `
    border-bottom: 6pt solid ${THEME_COLORS.light.secondary.main};
`,

    RADIUS: `10pt`,

    ROUNDED: `border-radius: 2.1em; overflow:hidden;`,

};

const CSS_FONTS = {
    SIZES: {
        MENU: '.9em',
        H1: '2.5rem',
        H2: '2rem',
        H3: '1.75rem',
        H4: '1.5rem',
        H5: '1.25rem',
        H6: '1rem',
        MD: '1.2rem',
        SM: '.9rem',
    },


    FAMILY: {

        //@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

        TITLE: {
            URL: "https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap'",
            FAMILY: "'Lato', sans-serif",
        },
        BODY: {
            // URL: "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap",
            FAMILY: "'Noto Sans', sans-serif",
        },

        GOOGLE: {
            URL: "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
            FAMILY: "'Roboto', sans-serif",
        },



    },

};

const MEDIA_SCREENS = {
    XS: 576,
    SM: 768,
    MD: 992,
    LG: 1400
};

const VALIDATE_MESSAGES = {
    required: '${label} es obligatorio!',
    types: {
        email: '${label} no es un email válido!',
        number: '${label} no es un numero!',
    },
    string: {
        range: '${label} debe estar entre ${min} y ${max}',
    },
    number: {
        range: '${label} debe estar entre ${min} y ${max}',
    }
};


export const THEME_VARS = {
    RADIUS: 10,
    RADIUS_SM: 9,
    GRADIENT_PRIMARY_MAIN: `linear-gradient(201deg, rgba(40,143,212,1) 5%, rgba(22,96,155,1) 88%)`,
    GRADIENT_SECONDARY_MAIN: `linear-gradient(201deg, rgba(40,143,212,1) 5%, rgba(22,96,155,1) 88%)`,


}




export {
    CSS_HELPERS_REACT,
    CSS_HELPERS,
    CSS_FONTS,
    MEDIA_SCREENS,
    THEME_COLORS,
    VALIDATE_MESSAGES,
}

