
import { BsBank2, BsCashCoin, BsDiagram3, BsFacebook, BsFileEarmarkArrowUp, BsInstagram, BsLinkedin, BsMegaphone, BsPeople, BsShieldLock, BsSignpost2 } from 'react-icons/bs';
import { VscOrganization } from 'react-icons/vsc';
import { RiUserStarLine } from 'react-icons/ri';
import { BiBarChartAlt2, BiBuildings, BiDownload, BiLandscape, BiLineChart, BiMapPin, BiNews, BiStreetView, BiTrophy, } from 'react-icons/bi';
import { IoAnalyticsOutline, IoLogoInstagram, IoLogoFacebook } from 'react-icons/io5';
import { HiOutlinePresentationChartBar } from 'react-icons/hi'
import Ahorro from '@theme/svg/ahorro.svg'
import Respaldo from '@theme/svg/respaldo.svg'
import Proteccion from '@theme/svg/proteccion.svg'
import Calendario from '@theme/svg/calendario.svg'
import CamillaAsitencia from '@theme/svg/camilla_asistencia.svg'
import Escritorio from '@theme/svg/escritorio.svg'
import Lapida from '@theme/svg/lapida.svg'
import EscudoProtegido from '@theme/svg/escudo_protegido.svg'
import SillaRuedas from '@theme/svg/silla_ruedas.svg'
import TiempoDinero from '@theme/svg/tiempo_dinero.svg'
import MoneyBag from '@theme/svg/moneyBag.svg';
import Circle370 from '@theme/svg/circle370.svg';
import WavePrimary from '@theme/svg/wavePrimary.svg';
import WaveSecondary from '@theme/svg/waveSecondary.svg';
import WaveThird from '@theme/svg/waveThird.svg';

import styled from 'styled-components';

import {
    ProteccionFamiliarIcon,
    RespaldoEconomicoIcon,
    FuturoConfiableIcon,
    Permanencia3aniosIcon,
    Permanencia90DiasIcon,
    Permanencia180DiasIcon,
    Permanencia370DiasIcon,
    ContrastLogo,
    ProvidaLogo,
    AhorroMensualIcon,
    Logo,
    LogoSquare,
    RentaplusIcon,
    SuperiorIcon,
    EstrategicoIcon,
    MasterIcon,
    FamilyIcon,
    EficazIcon
} from './svgs';
import { BiLogOut, BiCog } from 'react-icons/bi'
import { CgStories } from 'react-icons/cg';
import { MdFacebook } from 'react-icons/md';
import { FaFacebook } from 'react-icons/fa';
import { IoMdNotificationsOutline } from 'react-icons/io';

export const iconKeys = {
    "quienes_somos": <BsBank2 />,
    "gente_genesis": <VscOrganization />,
    "comunicados_interes": <BsMegaphone />,
    "inversion": <BiLineChart />,
    "largoPlazo": <BiLineChart />,
    "empleadores": <BiBuildings />,
    "personas": <BiStreetView />,
    "contactanos": <BiMapPin />,
    "nuestras_inversiones": <BiLineChart />,
    "noticias": <BiNews />,
    "hechos_relevantes": <BiTrophy />,
    "normativas": <BsSignpost2 />,
    "investors": <VscOrganization />,
    "portal_financiero": <BiBarChartAlt2 />,

    "descargas": <BiDownload />,

    "money_give": <IoAnalyticsOutline />,
    "money_back": <BsCashCoin />,
    "investment": <BsDiagram3 />,
    "unit_value": <RiUserStarLine />,
    "unit_value_grow": <HiOutlinePresentationChartBar />,
    "respaldo_economico": <RespaldoEconomicoIcon />,
    "proteccion_familiar": <ProteccionFamiliarIcon />,
    "ahorro_mensual": <AhorroMensualIcon />,
    "futuro_confiable": <FuturoConfiableIcon />,
    "permanencia_3_anios": <Permanencia3aniosIcon />,
    "permanencia_90_dias": <Permanencia90DiasIcon />,
    "permanencia_180_dias": <Permanencia180DiasIcon />,
    "permanencia_370_dias": <Permanencia370DiasIcon />,
    "contrast_logo": <ContrastLogo />,
    "logo": <Logo />,
    "logo_square": <LogoSquare />,
    "provida_logo": <ProvidaLogo />,
    "contenidos": <CgStories />,
    "galeria": <BiLandscape />,
    "usuarios": <BiCog />,
    "logout": <BiLogOut />,
    "clientepotencial": <BsPeople />,
    "rentaplus": <RentaplusIcon />,
    "superior": <SuperiorIcon />,
    "estrategico": <EstrategicoIcon />,
    "master": <MasterIcon />,
    "eficaz": <EficazIcon />,
    "horizonte": <FamilyIcon />,
    "seguridad_y_datos": <BsShieldLock />,
    "family_icon": <FamilyIcon />,

    "ahorro": <Ahorro />,
    "calendario": <Calendario />,
    "camilla_asistencia": <CamillaAsitencia />,
    "escritorio": <Escritorio />,
    "escudo_protegido": <EscudoProtegido />,
    "lapida": <Lapida />,
    "proteccion": <Proteccion />,
    "respaldo": <Respaldo />,
    "silla_ruedas": <SillaRuedas />,
    "tiempo_dinero": <TiempoDinero />,

    "primary": <WavePrimary />,
    "secondary": <WaveSecondary />,
    "terciary": <WaveThird />,

    "moneyBag": <MoneyBag />,
    "circle370": <Circle370 />,

    "facebook": <FaFacebook />,
    "instagram": <BsInstagram />,
    "linkedin": <BsLinkedin />,
    "documentos": <BsFileEarmarkArrowUp />,
    "notificaciones": <IoMdNotificationsOutline />

};

const IconSwitcher = ({ name, size, style, footer }) => {
    if (style) {
        const { fontSize, height } = style;
        return (
            <IconWrap2 fontSize={fontSize} height={height}>
                {iconKeys[name]}
            </IconWrap2>
        )
    }

    if (footer) {
        return (
            <IconWrap3 size={size} >
                {iconKeys[name]}
            </IconWrap3>
        )
    }

    return (
        <IconWrap size={size} >
            {iconKeys[name]}
        </IconWrap>
    )
}




export default IconSwitcher;


const IconWrap = styled.div`
    svg{
        height: ${props => props.size ? props.size : 3}em;
        width:100%;
    }
`;


const IconWrap2 = styled.div`
    svg{
        height:${props => props.height ? props.height : 0};
        font-size:${props => props.fontSize ? props.fontSize : 0};
    }
`;

const IconWrap3 = styled.div`
    svg{
        height: ${props => props.size ? props.size : 3}em;
        width:60px;
    }
`;
